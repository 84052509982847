import { createRouter, createWebHistory } from 'vue-router';

import Login from '@/views/login/Login.vue';

// 라우터 설계
const routes = [

    {
        path: '/',
        component: Login
    },
    {
        path: '/test',
        component: () => import('@/views/login/Test.vue')
    },
    {
        path: '/login/Join',
        component: () => import('@/views/login/Join.vue')
    },
    {
        path: '/MainLayout',
        component: () => import('@/views/MainLayout.vue'),
        children: [
            {
                path: 'ReportList',
                name: 'ReportList',
                component: () => import('@/views/content/admin/ReportList.vue')
            },
            {
                path: 'SchedulerList',
                name: 'SchedulerList',
                component: () => import('@/views/content/admin/SchedulerList.vue')
            },
            {
                path: 'SubscriptionApproval',
                name: 'SubscriptionApproval',
                component: () => import('@/views/content/admin/SubscriptionApproval.vue')
            },
            {
                path: 'License',
                name: 'admin3',
                component: () => import('@/views/content/admin/License.vue')
            },
            {
                path: 'AdminRegistration',
                name: 'admin4',
                component: () => import('@/views/content/admin/AdminRegistration.vue')
            },
            {
                path: 'Setting',
                name: 'admin5',
                component: () => import('@/views/content/admin/Setting.vue')
            },
            {
                path: 'MemberManagement',
                name: 'admin6',
                component: () => import('@/views/content/admin/MemberManagement.vue')
            },
            {
                path: 'UsageStatus',
                name: 'admin7',
                component: () => import('@/views/content/admin/UsageStatus.vue')
            },
            {
                path: 'UsageReport',
                name: 'UsageReport',
                component: () => import('@/views/content/admin/UsageReport.vue')
            }
            ,
            {
                path: 'UsageStatistics',
                name: 'admin8',
                component: () => import('@/views/content/admin/UsageStatistics.vue')
            }
            ,
            {
                path: 'ProjectSetting',
                name: 'admin9',
                component: () => import('@/views/content/admin/ProjectSetting.vue')
            }
            ,
            {
                path: 'AdminConnectionInfo',
                name: 'admin10',
                component: () => import('@/views/content/admin/AdminConnectionInfo.vue')
            }
            ,
            {
                path: 'ProjectUpdate',
                name: 'admin11',
                component: () => import('@/views/content/admin/ProjectUpdate.vue')
            }
            ,
            {
                path: 'UserRegistration',
                name: 'user1',
                component: () => import('@/views/content/user/UserRegistration.vue')
            }
            ,
            {
                path: 'CreateProject',
                name: 'CreateProject',
                component: () => import('@/views/content/user/CreateProject.vue')
            }
            ,
            {
                path: 'SettingProject',
                name: 'user3',
                component: () => import('@/views/content/user/SettingProject.vue')
            }
            ,
            {
                path: 'ConnectionInfo',
                name: 'user4',
                component: () => import('@/views/content/user/ConnectionInfo.vue')
            }
            ,
            {
                path: 'ReportRequest',
                name: 'user5',
                component: () => import('@/views/content/user/ReportRequest.vue')
            }

        ]
    }
]

// 라우터 생성
const router = createRouter({
    history: createWebHistory(),
    routes
});

// 라우터 추출 (main.js에서 import)
export { router };

